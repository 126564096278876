@import '../env/icofont.less';
@import '../env/vars.less';

body {
  header {
    font-family: 'Open sans', sans-serif;
    position: relative;
    z-index: 500;
    background: #FFF;
    box-shadow: @shadow;
    .header-cntnr {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      .logo-cntnr {
        margin-left: 25px;
        img {
          width: 100px;
        }
      }
      .block-right {
        display: flex;
        align-items: center;
        .n1-section {
          margin-right: 30px;
        }
      }
      a.contact {
        background: @black;
        border-radius: 7px;
        transition: all .3s;
        font-size: 18px;
        color: #FFF;
        text-shadow: 2px 2px 10px rgba(0, 0, 0, .5);
        position: relative;
        padding: 8px 30px 8px 50px;
        i {
          font-size: 40px;
          left: 10px;
          margin-right: 10px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
        &:hover{
          background: lighten(@black, 25%);
        }
      }
      .menu-pane {
        ul {
          li {
            position: relative;
            a {
              font-size: 1.1em;
              position: relative;
              display: block;
              color: @grey-text;
              font-weight: 700;
              transition: all .3s;
              &.contact {
                color: #fff;
              }
              &:hover {
                color: @red;
              }
            }
          }
        }
        @media @smdwn {
          ul li a.contact:hover {
            color: #fff;
          }
        }
      }
    }
  }
  &.sticking {
    header {
      // TRANSITION AJOUTEE EN JS
      position: fixed;
      z-index: 10;
      right: 0;
      left: 0;
      top: 0;
      transform: translateY(-100%);
      .header-cntnr {
        @media @smdwn {
          padding: 5px 10px;
        }
      }
    }
  }

  @media @sm {
    .header-cntnr {
      padding: 10px 25px;
    }
  }
  @media @lgup {
    header {
      .header-cntnr {
        padding: 15px 0;
        .menu-pane {
          flex: 1;
          padding: 0 2%;
          ul {
            display: flex;
            justify-content: space-between;
          }
        }
        #menu-toggle-btn {
          display: none;
        }
        .block-right {
          margin-right: 20px;
        }
      }
    }

    #mobile-menu-mask {
      display: none;
    }
  }
  @media @smdwn {
    .header-cntnr {
      padding: 3px 8px;
      .block-right {
        .n1-section {
          display: none;
        }
      }
    }
  }
  @media @lgdwn {
    header {
      .header-cntnr {
        .contact {
          margin-right: 15px;
        }
        #menu-toggle-btn {
          width: 45px;
          height: 45px;
          cursor: pointer;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          i {
            font-size: 2.2em;
            color: @dark-blue;
          }
        }
        .menu-pane {
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          background: #FFF;
          max-height: 0;
          overflow: hidden;
          transition: max-height .3s;
          ul.menu {
            padding-top: 10px;
            margin-bottom: 15px;
            text-align: center;
            li {
              a:not(.contact) {
                padding: 7px 0;
                &:hover {
                  background: @dark-blue;
                  color: #FFF;
                }
              }
            }
          }
          &.active {
            overflow-y: auto;
            max-height: ~"calc(100vh - 110px)";
          }
        }
        .social-networks {
          display: none;
        }
      }
    }

    #mobile-menu-mask {
      opacity: 0;
      background: #000;
      z-index: 10;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      transition: opacity .4s;
      &.active {
        position: fixed;
        opacity: .3;
      }
    }
  }
  @media @xl {
    header {
      .header-cntnr {

        .logo-cntnr {
          margin-left: 100px;
          img {
            width: 200px;
          }
        }
        .menu-pane {
          padding: 0 4%;
        }
        .block-right {
          margin-right: 60px;
        }
      }
    }
  }
  @media (min-width: 1650px) {
    header {
      .header-cntnr {
        .menu-pane {
          padding: 0 8%;
        }
      }
    }
  }
  @media @xxs{
    .header-cntnr {
      .block-right {
        .contact {
          display: none;
        }
      }
    }
  }
  &.sticked {
    header {
      transform: translateY(0%);
    }
  }
}