
html body {
  section {
    &.zdt{
      position: relative;
      .img-house{
        max-width: 100%;
      }
      .panel-group{
        .panel{
          .panel-heading{
            background-color: transparent;
            .panel-title{
              i{
                color : @red;
                font-weight: 400;
              }
              span{
                color: @dark-blue;
                text-transform: uppercase;
                font-size: 15px;
                font-weight: 800;
              }
            }
          }
          .panel-body{
            font-size: 1em;
          }
        }
      }
    }

    @media @smdwn{
      .img-house{
        margin-bottom: 25px;

      }
    }

  }
}
