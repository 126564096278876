
#legales{
  display:none;

}

#legales,
#legales h1,
#legales h2{
  color:#FFF;
}


#footer{
}


footer#footer{
  padding-top:30px;
  padding-bottom: 0;
  background: @dark-blue;
  .text-footer{
    color: @white;
    margin-top: 50px;
    margin-bottom: 30px;
  }
  .n1-section {
    margin-bottom: 50px;
    color: @white;
  }
  #footer_copyright{
    background: #03141c;
    font-size: 12px;
    p{
      margin: 0;
    }
  }
  a {
    color: #FFF;
  }
  .logo-footer {
    img {
      max-width: 250px;
    }
  }
}