@import '../env/icofont.less';
@import '../env/vars.less';


@media only screen and (max-width: 991px){
  html #intro_content{
    text-align:left;
  }

  html #intro h2{
    font-size: 30px;
    line-height: 30px;
    padding:0;
  }

  .pastille{
    width:90px;
    height:90px;
    padding: 13px;
  }

  #intro .args{
    margin-bottom: 20px;
  }

}

@media only screen and (max-width: 767px){

  html #intro_content{
    text-align:left;
  }
}

html body {
  section {
    .section-flyer {
      background-color: @yellow;

      // Masquage du bandeau (on ne masque pas le .section-flyer car le flex est en space-between)
      & > *{
        display:none;
      }
      .text-section-flyer {
        display: flex;
        align-items: center;
        color: black;
        padding-top: 11px;
        padding-bottom: 11px;
        margin-right: auto;
        margin-left: auto;
        .icon-alert {
          font-size: 27px;
          color: @orange;
        }
        .icon-arrow-right {
          font-size: 20px;
          margin-left: 20px;
          margin-right: 10px;
        }
        .style-text {
          font-style: italic;
          a{
            color: inherit;
          }
          .bold-text{
            font-weight: bolder;
          }
        }
      }
      @media @smup{
          .text-section-flyer {
            .style-text {
              border-bottom: 1px solid black;
            }
        }
      }
      @media @smdwn{
        .text-section-flyer {
          flex-direction: column;
          .style-text {
            border-top: 1px solid black;
            padding-top : 10px;
            margin-top : 10px;
          }
        }
      }
    }
    &#intro {
      padding:0;
      position: relative;
      min-height: ~"calc(100vh - 75px)";
      background-image:url(../img/intro-banner.jpg);
      #intro_content {
        padding-top: 0;
        padding-bottom: 0;
        .intro_text {
          text-shadow: 3px 3px 5px rgba(0,0,0,0.3);
          h2{
            font-size: 25px;
            color: #FFF;
            margin-bottom: 0px;
            line-height: 40px;
            &:after{
              display: none;
            }
          }
          h3 {
            font-family: 'Montserrat';
            padding: 15px 0;
            text-transform: none;
            font-style: italic;
            font-size: 21px;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 20px;
            &:after {
              max-width: 350px;
            }

          }
          .args {
            li {
              display: flex;
              align-items: center;
              font-weight: 500;
              i {
                color: @red;
                margin-right: 5px;
                font-size: 32px;
              }
            }
          }
        }
      }
      .brand-section {
        background: @blue-degr;
        .brand-item{
          height: 100px;
          display: flex;
          align-content: center;
          align-items: center;
          justify-content: center;
        }
        .pastille-price {
          position: absolute;
          bottom: 100%;
        }
        .wording-testimonials {
          padding-top: 6px;
          padding-bottom: 6px;
          h3 {
            text-transform: none;
            font-weight: 600;
            margin-bottom: 0;
          }
        }
        img {
          max-width: 100%;
          height: auto;
        }
      }

      @media @smdwn {
        .args {
          font-size: 16px;
          text-align: left;
          li {
            padding: 4px 0;
          }
        }

        h3 {
          font-size: 16px;
        }

        .brand-section {
          img {
            max-height: 50px;
          }
        }
      }
      @media @sm {
        .args {
          font-size: 18px;
          li {
            padding: 5px 0;
            justify-content: center;
          }
          i {
            display: none;
          }
        }

        h3 {
          font-size: 18px;
        }
      }
      @media @mddwn {
        #intro_content {
          text-align: center;
          padding-top: 50px;
        }
        .overlay{
          padding-bottom: 40px;
        }
        .brand-section{
          padding: 5px 0;
        }
      }
      @media @mdup {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .args {
          font-size: 18px;
          li {
            padding: 6px 0;
          }
        }
        h2 {
          max-width: 750px;
        }

        h3 {
          font-size: 20px;
        }
        .brand-section {
          position: relative;
          padding: 15px 0;
        }
      }
    }
  }
}
