@import '../env/icofont.less';
@import '../env/vars.less';

html body {
  section {
    &#savoir-faire {
      background-image: url(../img/banner-img.jpg);
      .titlebar{
        h2{
          color: #FFF;
        }
      }
      i{
        color: #FFF;
        font-size: 100px;
      }
      p{
        font-size: 1em;
        font-weight: 400;
      }
      h4 {
        color : @red;
        font-weight: 700;
      }
      @media @mdup {
        padding: 70px 0;
        h4 {
          margin-bottom: 25px;
        }
      }
      @media @sm {
        padding: 50px 0;
        .features-box {
          display: flex;
          .icon-box {
            margin-right: 30px;
          }
          h4 {
            margin-bottom: 16px;
          }
        }
      }
      @media @smup {
        h4 {
          font-size: 18px;
        }
      }
      @media @smdwn {
        padding: 35px 0;
        h4 {
          margin-bottom: 15px;
          font-size: 16px;
        }
      }
    }
  }
}