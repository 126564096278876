@import '../env/icofont.less';
@import '../env/vars.less';

html body {
  .testimonials {
    background-color: @grey-bg;
    .btn {
      margin-top: 30px;
    }
    .testi-content{
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 140px;
    }
    @media @mdup {
      padding: 70px 0;
    }
    @media @sm {
      padding: 50px 0;
      .testi-content {
        min-height: 162px;
      }
    }
    @media @smdwn {
      padding: 35px 0;
      .testi-content {
        margin: 0 0 20px;
      }
    }
  }
}


.testimonial .client-img img{
  width:100%;
  height:auto;
}


.testimonial .testi-author{
  padding-left:25px;
}
