@import '../env/vars.less';

.btn{
  font-weight: 600;
  text-transform: none;
  font-size: 1em;
  border-radius: 5px;
  text-align: center;
  padding: 6px 20px;
  min-width: 200px;
  box-shadow: 0 3px 3px rgba(0,0,0,0.15);
  span{
    position : relative;
    i{
      position: absolute;
      font-size: 1em;
      margin: 0;
      left: -25px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &.btn-red{
    background: @red;
    color: #FFF !important;
    &:hover {
      background: darken(@red, 15%);
    }
  }
  &.btn-theme{
    background: @dark-blue;
    color: #FFF !important;
    &:hover {
      background: darken(@dark-blue, 15%);
    }
  }
}

.chapo {
  color: @red;
  text-align: center;
  margin-bottom: 8px;
  font-size: 18px;
}

.highligh-content{
  color: @dark-blue;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 50px;
}


.n1-section {
  #n1 {
    font-weight: bolder;
  }
  img {
    max-width: 17px;
    margin-left: 7px;
  }
}

ul.cool-ul{
  padding-left : 27px;
  border-left : solid 1px @dark-blue;
  li{
    margin-bottom: 10px;
    strong{
      color: @red;
    }
  }
}