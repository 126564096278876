@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,400i,600,700,700i,800');
@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?2dgxgf');
  src: url('../fonts/icomoon.eot?2dgxgf#iefix') format('embedded-opentype'), url('../fonts/icomoon.ttf?2dgxgf') format('truetype'), url('../fonts/icomoon.woff?2dgxgf') format('woff'), url('../fonts/icomoon.svg?2dgxgf#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-workman:before {
  content: "\e900";
}
.icon-pig:before {
  content: "\e901";
}
.icon-arrow:before {
  content: "\e902";
}
.icon-check-circle:before {
  content: "\e903";
}
.icon-arrow-right:before {
  content: "\e904";
}
.icon-check:before {
  content: "\e905";
}
.icon-info:before {
  content: "\e906";
}
.icon-phone:before {
  content: "\e907";
}
.icon-lock:before {
  content: "\e908";
}
.icon-menu:before {
  content: "\e909";
}
.icon-marker:before {
  content: "\e90b";
}
.icon-marker-outline:before {
  content: "\e90c";
}
.icon-stars:before {
  content: "\e90d";
}
.icon-alert:before {
  content: "\e90a";
}
body header {
  font-family: 'Open sans', sans-serif;
  position: relative;
  z-index: 500;
  background: #FFF;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
}
body header .header-cntnr {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}
body header .header-cntnr .logo-cntnr {
  margin-left: 25px;
}
body header .header-cntnr .logo-cntnr img {
  width: 100px;
}
body header .header-cntnr .block-right {
  display: flex;
  align-items: center;
}
body header .header-cntnr .block-right .n1-section {
  margin-right: 30px;
}
body header .header-cntnr a.contact {
  background: #111;
  border-radius: 7px;
  transition: all .3s;
  font-size: 18px;
  color: #FFF;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
  position: relative;
  padding: 8px 30px 8px 50px;
}
body header .header-cntnr a.contact i {
  font-size: 40px;
  left: 10px;
  margin-right: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
body header .header-cntnr a.contact:hover {
  background: #515151;
}
body header .header-cntnr .menu-pane ul li {
  position: relative;
}
body header .header-cntnr .menu-pane ul li a {
  font-size: 1.1em;
  position: relative;
  display: block;
  color: #333333;
  font-weight: 700;
  transition: all .3s;
}
body header .header-cntnr .menu-pane ul li a.contact {
  color: #fff;
}
body header .header-cntnr .menu-pane ul li a:hover {
  color: #f05348;
}
@media (max-width: 767px) {
  body header .header-cntnr .menu-pane ul li a.contact:hover {
    color: #fff;
  }
}
body.sticking header {
  position: fixed;
  z-index: 10;
  right: 0;
  left: 0;
  top: 0;
  transform: translateY(-100%);
}
@media (max-width: 767px) {
  body.sticking header .header-cntnr {
    padding: 5px 10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  body .header-cntnr {
    padding: 10px 25px;
  }
}
@media (min-width: 1200px) {
  body header .header-cntnr {
    padding: 15px 0;
  }
  body header .header-cntnr .menu-pane {
    flex: 1;
    padding: 0 2%;
  }
  body header .header-cntnr .menu-pane ul {
    display: flex;
    justify-content: space-between;
  }
  body header .header-cntnr #menu-toggle-btn {
    display: none;
  }
  body header .header-cntnr .block-right {
    margin-right: 20px;
  }
  body #mobile-menu-mask {
    display: none;
  }
}
@media (max-width: 767px) {
  body .header-cntnr {
    padding: 3px 8px;
  }
  body .header-cntnr .block-right .n1-section {
    display: none;
  }
}
@media (max-width: 1199px) {
  body header .header-cntnr .contact {
    margin-right: 15px;
  }
  body header .header-cntnr #menu-toggle-btn {
    width: 45px;
    height: 45px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  body header .header-cntnr #menu-toggle-btn i {
    font-size: 2.2em;
    color: #0d1f28;
  }
  body header .header-cntnr .menu-pane {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #FFF;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s;
  }
  body header .header-cntnr .menu-pane ul.menu {
    padding-top: 10px;
    margin-bottom: 15px;
    text-align: center;
  }
  body header .header-cntnr .menu-pane ul.menu li a:not(.contact) {
    padding: 7px 0;
  }
  body header .header-cntnr .menu-pane ul.menu li a:not(.contact):hover {
    background: #0d1f28;
    color: #FFF;
  }
  body header .header-cntnr .menu-pane.active {
    overflow-y: auto;
    max-height: calc(100vh - 110px);
  }
  body header .header-cntnr .social-networks {
    display: none;
  }
  body #mobile-menu-mask {
    opacity: 0;
    background: #000;
    z-index: 10;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: opacity .4s;
  }
  body #mobile-menu-mask.active {
    position: fixed;
    opacity: .3;
  }
}
@media (min-width: 1500px) {
  body header .header-cntnr .logo-cntnr {
    margin-left: 100px;
  }
  body header .header-cntnr .logo-cntnr img {
    width: 200px;
  }
  body header .header-cntnr .menu-pane {
    padding: 0 4%;
  }
  body header .header-cntnr .block-right {
    margin-right: 60px;
  }
}
@media (min-width: 1650px) {
  body header .header-cntnr .menu-pane {
    padding: 0 8%;
  }
}
@media (max-width: 575px) {
  body .header-cntnr .block-right .contact {
    display: none;
  }
}
body.sticked header {
  transform: translateY(0%);
}
@media only screen and (max-width: 991px) {
  html #intro_content {
    text-align: left;
  }
  html #intro h2 {
    font-size: 30px;
    line-height: 30px;
    padding: 0;
  }
  .pastille {
    width: 90px;
    height: 90px;
    padding: 13px;
  }
  #intro .args {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 767px) {
  html #intro_content {
    text-align: left;
  }
}
html body section .section-flyer {
  background-color: #f3d055;
}
html body section .section-flyer > * {
  display: none;
}
html body section .section-flyer .text-section-flyer {
  display: flex;
  align-items: center;
  color: black;
  padding-top: 11px;
  padding-bottom: 11px;
  margin-right: auto;
  margin-left: auto;
}
html body section .section-flyer .text-section-flyer .icon-alert {
  font-size: 27px;
  color: #ff8400;
}
html body section .section-flyer .text-section-flyer .icon-arrow-right {
  font-size: 20px;
  margin-left: 20px;
  margin-right: 10px;
}
html body section .section-flyer .text-section-flyer .style-text {
  font-style: italic;
}
html body section .section-flyer .text-section-flyer .style-text a {
  color: inherit;
}
html body section .section-flyer .text-section-flyer .style-text .bold-text {
  font-weight: bolder;
}
@media (min-width: 768px) {
  html body section .section-flyer .text-section-flyer .style-text {
    border-bottom: 1px solid black;
  }
}
@media (max-width: 767px) {
  html body section .section-flyer .text-section-flyer {
    flex-direction: column;
  }
  html body section .section-flyer .text-section-flyer .style-text {
    border-top: 1px solid black;
    padding-top: 10px;
    margin-top: 10px;
  }
}
html body section#intro {
  padding: 0;
  position: relative;
  min-height: calc(100vh - 75px);
  background-image: url(../img/intro-banner.jpg);
}
html body section#intro #intro_content {
  padding-top: 0;
  padding-bottom: 0;
}
html body section#intro #intro_content .intro_text {
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
}
html body section#intro #intro_content .intro_text h2 {
  font-size: 25px;
  color: #FFF;
  margin-bottom: 0px;
  line-height: 40px;
}
html body section#intro #intro_content .intro_text h2:after {
  display: none;
}
html body section#intro #intro_content .intro_text h3 {
  font-family: 'Montserrat';
  padding: 15px 0;
  text-transform: none;
  font-style: italic;
  font-size: 21px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 20px;
}
html body section#intro #intro_content .intro_text h3:after {
  max-width: 350px;
}
html body section#intro #intro_content .intro_text .args li {
  display: flex;
  align-items: center;
  font-weight: 500;
}
html body section#intro #intro_content .intro_text .args li i {
  color: #f05348;
  margin-right: 5px;
  font-size: 32px;
}
html body section#intro .brand-section {
  background: linear-gradient(90deg, #16303e, #0d1f28);
}
html body section#intro .brand-section .brand-item {
  height: 100px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}
html body section#intro .brand-section .pastille-price {
  position: absolute;
  bottom: 100%;
}
html body section#intro .brand-section .wording-testimonials {
  padding-top: 6px;
  padding-bottom: 6px;
}
html body section#intro .brand-section .wording-testimonials h3 {
  text-transform: none;
  font-weight: 600;
  margin-bottom: 0;
}
html body section#intro .brand-section img {
  max-width: 100%;
  height: auto;
}
@media (max-width: 767px) {
  html body section#intro .args {
    font-size: 16px;
    text-align: left;
  }
  html body section#intro .args li {
    padding: 4px 0;
  }
  html body section#intro h3 {
    font-size: 16px;
  }
  html body section#intro .brand-section img {
    max-height: 50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  html body section#intro .args {
    font-size: 18px;
  }
  html body section#intro .args li {
    padding: 5px 0;
    justify-content: center;
  }
  html body section#intro .args i {
    display: none;
  }
  html body section#intro h3 {
    font-size: 18px;
  }
}
@media (max-width: 991px) {
  html body section#intro #intro_content {
    text-align: center;
    padding-top: 50px;
  }
  html body section#intro .overlay {
    padding-bottom: 40px;
  }
  html body section#intro .brand-section {
    padding: 5px 0;
  }
}
@media (min-width: 992px) {
  html body section#intro {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  html body section#intro .args {
    font-size: 18px;
  }
  html body section#intro .args li {
    padding: 6px 0;
  }
  html body section#intro h2 {
    max-width: 750px;
  }
  html body section#intro h3 {
    font-size: 20px;
  }
  html body section#intro .brand-section {
    position: relative;
    padding: 15px 0;
  }
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus,
.nav-tabs > li > a:hover {
  color: #0d1f28;
  background-color: transparent;
}
.nav-tabs > li > a {
  background-color: transparent;
  color: #5E5E5E;
}
.tab-pane {
  color: #5E5E5E;
}
#le-groupe .tab-content > .tab-pane p {
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 25px;
  font-weight: 400;
}
html body section .chapo {
  color: #f05348;
  font-weight: 600;
}
html body section .titlebar h2 {
  color: #111;
}
@media (min-width: 768px) {
  html body section#le-groupe {
    padding: 50px 0;
  }
  html body section#le-groupe #tabs-holder ul.nav-tabs > li:first-child {
    border-right: 1px solid #999;
    padding-right: 20px;
  }
  html body section#le-groupe #tabs-holder ul.nav-tabs > li:last-child {
    padding-left: 20px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  html body section#le-groupe img.img-responsive {
    width: 100%;
    height: auto;
  }
}
@media (max-width: 767px) {
  html body section#le-groupe {
    padding: 30px 0;
  }
  html body section#le-groupe #tabs-holder ul.nav-tabs {
    text-align: center;
  }
}
html body section#savoir-faire {
  background-image: url(../img/banner-img.jpg);
}
html body section#savoir-faire .titlebar h2 {
  color: #FFF;
}
html body section#savoir-faire i {
  color: #FFF;
  font-size: 100px;
}
html body section#savoir-faire p {
  font-size: 1em;
  font-weight: 400;
}
html body section#savoir-faire h4 {
  color: #f05348;
  font-weight: 700;
}
@media (min-width: 992px) {
  html body section#savoir-faire {
    padding: 70px 0;
  }
  html body section#savoir-faire h4 {
    margin-bottom: 25px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  html body section#savoir-faire {
    padding: 50px 0;
  }
  html body section#savoir-faire .features-box {
    display: flex;
  }
  html body section#savoir-faire .features-box .icon-box {
    margin-right: 30px;
  }
  html body section#savoir-faire .features-box h4 {
    margin-bottom: 16px;
  }
}
@media (min-width: 768px) {
  html body section#savoir-faire h4 {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  html body section#savoir-faire {
    padding: 35px 0;
  }
  html body section#savoir-faire h4 {
    margin-bottom: 15px;
    font-size: 16px;
  }
}
html body section.main-content {
  position: relative;
  background-color: #f5f5f5;
}
html body section.main-content .medias-cntnr {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 50px 0;
}
html body section.main-content .medias-cntnr img:not(:last-child) {
  margin-right: 75px;
}
html body section.main-content .features-box .features-text h4 span:first-child {
  color: #0d1f28;
  font-size: 21px;
  font-weight: 800;
}
html body section.main-content .features-box .features-text h4 span:last-child {
  color: #f05348;
  font-size: 16px;
  font-weight: 600;
}
html body section.main-content .features-box .features-text p {
  font-size: 1em;
  color: #4c4c4c;
  font-weight: 400;
}
html body section.main-content .flying-img {
  position: absolute;
  left: 0;
  bottom: 0;
}
@media (max-width: 1499px) {
  html body section .flying-img {
    display: none;
  }
}
html body section.zdt {
  position: relative;
}
html body section.zdt .img-house {
  max-width: 100%;
}
html body section.zdt .panel-group .panel .panel-heading {
  background-color: transparent;
}
html body section.zdt .panel-group .panel .panel-heading .panel-title i {
  color: #f05348;
  font-weight: 400;
}
html body section.zdt .panel-group .panel .panel-heading .panel-title span {
  color: #0d1f28;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 800;
}
html body section.zdt .panel-group .panel .panel-body {
  font-size: 1em;
}
@media (max-width: 767px) {
  html body section .img-house {
    margin-bottom: 25px;
  }
}
html body section#partners {
  position: relative;
  background-color: #f5f5f5;
  text-align: center;
}
html body section#partners img {
  max-width: 100%;
}
html body section#clients {
  background-image: url(../img/banner-img-2.jpg);
}
@media (min-width: 768px) {
  html body section#clients {
    padding: 60px 0;
  }
  html body section#clients p {
    margin-bottom: 25px;
  }
}
@media (max-width: 767px) {
  html body section#clients {
    padding: 30px 0;
  }
  html body section#clients img {
    max-width: 65px;
  }
  html body section#clients p {
    margin-bottom: 15px;
  }
}
#clients {
  background-size: cover;
}
#clients .titlebar h2 {
  color: #FFF;
}
#clients .tv-logo-wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 150px;
}
#clients .tv-logo-wrapper img {
  margin: auto;
}
html body section.contact-info {
  position: relative;
}
html body section.contact-info .contact-info i {
  font-size: 125px;
  color: #f05348;
}
html body section.contact-info .contact-info .contact_description,
html body section.contact-info .contact-info .contact_description a {
  color: #f05348;
  font-size: 1em;
  font-weight: 700;
}
html body .testimonials {
  background-color: #f5f5f5;
}
html body .testimonials .btn {
  margin-top: 30px;
}
html body .testimonials .testi-content {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 140px;
}
@media (min-width: 992px) {
  html body .testimonials {
    padding: 70px 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  html body .testimonials {
    padding: 50px 0;
  }
  html body .testimonials .testi-content {
    min-height: 162px;
  }
}
@media (max-width: 767px) {
  html body .testimonials {
    padding: 35px 0;
  }
  html body .testimonials .testi-content {
    margin: 0 0 20px;
  }
}
.testimonial .client-img img {
  width: 100%;
  height: auto;
}
.testimonial .testi-author {
  padding-left: 25px;
}
#legales {
  display: none;
}
#legales,
#legales h1,
#legales h2 {
  color: #FFF;
}
footer#footer {
  padding-top: 30px;
  padding-bottom: 0;
  background: #0d1f28;
}
footer#footer .text-footer {
  color: #FFF;
  margin-top: 50px;
  margin-bottom: 30px;
}
footer#footer .n1-section {
  margin-bottom: 50px;
  color: #FFF;
}
footer#footer #footer_copyright {
  background: #03141c;
  font-size: 12px;
}
footer#footer #footer_copyright p {
  margin: 0;
}
footer#footer a {
  color: #FFF;
}
footer#footer .logo-footer img {
  max-width: 250px;
}
h2,
.titlebar h2 {
  font-weight: 700;
  text-transform: none;
}
h2:after,
.titlebar h2:after {
  background: #333;
}
#scrollUp {
  background-color: #304966;
}
.overlay {
  background: transparent;
}
#statistic_banner {
  background-attachment: initial!important;
}
#statistic_banner .statistic-number {
  font-family: 'Open Sans', sans-serif;
  letter-spacing: -3px;
  font-size: 57px;
  font-weight: 600;
  margin-bottom: 0;
}
#statistic_banner .statistic-text {
  font-size: 19px;
  font-weight: 400;
  font-family: Cabin;
}
.hover-overlay:hover .item-overlay {
  opacity: .8;
}
.overlay-content h4 {
  font-weight: 600;
  font-size: 28px;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
}
.overlay-content h5 {
  font-weight: 600;
  font-size: 20px;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
}
.btn {
  font-weight: 600;
  text-transform: none;
  font-size: 1em;
  border-radius: 5px;
  text-align: center;
  padding: 6px 20px;
  min-width: 200px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
}
.btn span {
  position: relative;
}
.btn span i {
  position: absolute;
  font-size: 1em;
  margin: 0;
  left: -25px;
  top: 50%;
  transform: translateY(-50%);
}
.btn.btn-red {
  background: #f05348;
  color: #FFF !important;
}
.btn.btn-red:hover {
  background: #da1f12;
}
.btn.btn-theme {
  background: #0d1f28;
  color: #FFF !important;
}
.btn.btn-theme:hover {
  background: #000000;
}
.chapo {
  color: #f05348;
  text-align: center;
  margin-bottom: 8px;
  font-size: 18px;
}
.highligh-content {
  color: #0d1f28;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 50px;
}
.n1-section #n1 {
  font-weight: bolder;
}
.n1-section img {
  max-width: 17px;
  margin-left: 7px;
}
ul.cool-ul {
  padding-left: 27px;
  border-left: solid 1px #0d1f28;
}
ul.cool-ul li {
  margin-bottom: 10px;
}
ul.cool-ul li strong {
  color: #f05348;
}
html body #simulator {
  border-radius: 10px;
}
html body #simulator .form-heading {
  padding-top: 25px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 15px;
  background: #0d1f28;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
}
html body #simulator .form-heading .form-title {
  font-size: 19px;
  line-height: 19px;
  font-weight: 800;
  margin-bottom: 12px;
}
html body #simulator .form-heading .form-subtitle {
  font-size: 15px;
  line-height: 15px;
  font-weight: 700;
  color: #f05348;
  font-style: italic;
}
html body #simulator .form-body {
  background-color: #fff;
  padding-top: 25px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 15px;
}
html body #simulator .form-body .form-question {
  font-size: 17px;
  line-height: 17px;
  font-weight: 600;
  color: #f05348;
  margin-bottom: 15px;
}
html body #simulator .form-body .radio-cntnr {
  position: relative;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
html body #simulator .form-body .radio-cntnr input {
  background: none;
  margin: 0;
  margin-right: 12px;
}
html body #simulator .form-body .radio-cntnr label {
  color: #0d1f28;
  font-size: 1em;
  margin-bottom: 0;
  font-weight: 600;
}
html body #simulator .form-body .input-cntnr {
  margin-bottom: 12px;
  position: relative;
}
html body #simulator .form-body .input-cntnr input.form-control {
  background: none;
  margin: 0;
  padding-left: 25px;
  padding-right: 25px;
}
html body #simulator .form-body .input-cntnr input.form-control:focus {
  border-color: #0d1f28;
  outline: 0;
  box-shadow: inset 0 1px 1px #0d1f28, 0 1px 1px #0d1f28;
}
html body #simulator .form-body .input-cntnr input.form-control.error {
  border-color: #b81515;
}
html body #simulator .form-body .input-cntnr input.form-control.error:focus {
  border-color: #b81515;
  box-shadow: inset 0 1px 1px #b81515, 0 1px 1px #b81515;
}
html body #simulator .form-body .input-cntnr:after {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e908";
  position: absolute;
  right: 13px;
  top: 17px;
  transform: translateY(-50%);
  color: #b7b0b0;
  display: block;
  font-size: 1.5em;
}
html body #simulator .form-body .btn {
  padding: 8px 40px;
}
html body #simulator .form-body .form-separator {
  border: solid 1px #f5f5f5;
  margin-bottom: 18px;
  margin-top: 18px;
}
html body #simulator .form-body .form-infos {
  margin-top: 7px;
  color: #0d1f28;
  font-style: italic;
  font-size: .85em;
}
html body #simulator .form-body .form-infos span {
  margin-right: 10px;
}
html body #simulator .form-body .error {
  color: #b81515;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 0;
}
html body #simulator #confirm-pane {
  color: #4c4c4c;
}
@media (min-width: 768px) {
  html body #simulator .form-heading h3 {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  html body #simulator .form-heading h3 {
    font-size: 14px;
  }
}
@media (min-width: 992px) {
  html body #simulator label.error[generated="true"] {
    position: absolute;
    right: 100%;
    top: 0;
    z-index: 1000;
    display: inline-block;
    background: #b81515;
    color: #FFF;
    padding: 8px;
    width: 175px;
    margin-right: 15px;
    font-weight: 500;
    font-size: 1.2rem;
  }
  html body #simulator label.error[generated="true"]:after {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(184, 21, 21, 0);
    border-left-color: #b81515;
    border-width: 8px;
    margin-top: -8px;
  }
  html body #simulator #demande-error-container {
    position: relative;
  }
}
@media (max-width: 991px) {
  html body #simulator label.error[generated="true"] {
    display: block;
    text-align: left;
  }
  html body #simulator #demande-error-container {
    margin-bottom: 15px;
  }
  html body #simulator #demande-error-container .radio-cntnr:nth-child(2) {
    margin-bottom: 0px;
  }
}
body {
  font-family: 'Montserrat';
  font-weight: 400;
  color: #0d1f28;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Montserrat';
  line-height: 1.1;
  color: inherit;
}
html body section {
  padding: 75px 0;
}
html body .titlebar h2 {
  color: #4c4c4c;
}
html body .titlebar h2:after {
  background: #f05348;
}
html body .bold {
  font-weight: 600;
}
html body .italic {
  font-style: italic;
}
html body .inline-block {
  display: inline-block;
}
html body .text-white {
  color: #FFF;
}
html body .red {
  color: #f05348;
}
html body .underline {
  position: relative;
}
html body .underline:after {
  content: "";
  border-bottom: 1px solid #f05348;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}
@media (min-width: 992px) {
  html body .underline:after {
    width: 100%;
  }
}
@media (max-width: 991px) {
  html body .underline:after {
    max-width: 230px;
    margin: 0 auto;
  }
}
html body .enlarge-ur-txt {
  font-size: 1.2em;
}
html body .give-me-space {
  margin-bottom: 75px;
}
@media (min-width: 992px) {
  html body .titlebar h2 {
    margin-bottom: 60px;
    font-size: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  html body .titlebar h2 {
    margin-bottom: 45px;
    font-size: 26px;
  }
}
@media (max-width: 767px) {
  html body .titlebar h2 {
    margin-bottom: 25px;
    font-size: 20px;
  }
  html body #scrollUp {
    display: none !important;
  }
}
