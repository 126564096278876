
html body {
  section {
    &#partners{
      position: relative;
      background-color: @grey-bg;
      text-align: center;
      img{
        max-width: 100%;
      }
    }
  }
}
