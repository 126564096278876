@import '../env/icofont.less';
@import '../env/vars.less';

html body {

  //BANNER 1 COL AVEC LOGOS PARTNERS
  section#clients {
    background-image: url(../img/banner-img-2.jpg);

    @media @smup {
      padding: 60px 0;
      p{
        margin-bottom: 25px;
      }
    }
    @media @smdwn {
      padding: 30px 0;
      img {
        max-width: 65px;
      }
      p{
        margin-bottom: 15px;
      }
    }
  }
}


#clients{
  background-size: cover;
}

#clients .titlebar h2{
  color:#FFF;
}


#clients .tv-logo-wrapper{
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 150px;
}

#clients .tv-logo-wrapper img{
  margin:auto;
}
