
html body {
  section {
    &.main-content {
      position: relative;
      background-color: @grey-bg;

      .medias-cntnr {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin: 50px 0;
        img:not(:last-child) {
          margin-right: 75px
        }
      }

      .features-box {
        .features-text {
          h4 {
            span {
              &:first-child {
                color: @dark-blue;
                font-size: 21px;
                font-weight: 800;
              }
              &:last-child {
                color: @red;
                font-size: 16px;
                font-weight: 600;
              }
            }
          }
          p {
            font-size: 1em;
            color: @grey-font;
            font-weight: 400;
          }
        }
      }

      .flying-img {
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
    @media @xldwn {
      .flying-img {
        display: none;
      }
    }
  }
}
