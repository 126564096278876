
html body {
  section {
    &.contact-info{
      position: relative;
      .contact-info{
        i{
          font-size: 125px;
          color: @red;
        }
        .contact_description, .contact_description a{
            color: @red;
            font-size: 1em;
            font-weight: 700;
        }
      }
    }
  }
}
