h2,
.titlebar h2{
  font-weight: 700;
  text-transform: none;
}

h2:after,
.titlebar h2:after{
  background: #333;
}

#scrollUp{
  background-color:#304966;
}


.overlay{
  background: transparent;
}

#statistic_banner{
  background-attachment: initial!important;
}

#statistic_banner .statistic-number{
  font-family: 'Open Sans', sans-serif;
  letter-spacing: -3px;
  font-size: 57px;
  font-weight: 600;
  margin-bottom: 0;
}

#statistic_banner .statistic-text{
  font-size: 19px;
  font-weight: 400;
  font-family: Cabin;
}





.hover-overlay:hover .item-overlay{
  opacity:.8;
}

.overlay-content h4{
  font-weight:600;
  font-size:28px;
  text-shadow: 1px 1px 5px rgba(0,0,0,.5);
}

.overlay-content h5{
  font-weight:600;
  font-size:20px;
  text-shadow: 1px 1px 5px rgba(0,0,0,.5);
}

