@import '../env/vars-icofont.less';
@import '../env/vars.less';

html body {
  #simulator {
    @form-padding : 25px;
    border-radius: 10px;
    //overflow: hidden;
    .form-heading{
      padding-top:@form-padding;
      padding-left:@form-padding;
      padding-right:@form-padding;
      padding-bottom:15px;
      background: @dark-blue;
      text-shadow: 0 0 5px rgba(0,0,0,0.6);
      .form-title{
        font-size: 19px;
        line-height: 19px;
        font-weight: 800;
        margin-bottom: 12px;
      }
      .form-subtitle{
        font-size: 15px;
        line-height: 15px;
        font-weight: 700;
        color: @red;
        font-style: italic;
      }
    }

    .form-body {
      background-color: #fff;
      padding-top:@form-padding;
      padding-left:@form-padding;
      padding-right:@form-padding;
      padding-bottom:15px;
      .form-question{
        font-size: 17px;
        line-height: 17px;
        font-weight: 600;
        color: @red;
        margin-bottom: 15px;
      }
      .radio-cntnr{
        position: relative;
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        input{
          background: none;
          margin: 0;
          margin-right: 12px;
        }
        label{
          color : @dark-blue;
          font-size: 1em;
          margin-bottom: 0;
          font-weight: 600;
        }
      }
      .input-cntnr{
        margin-bottom: 12px;
        position: relative;
        input.form-control{
          background: none;
          margin: 0;
          padding-left: 25px;
          padding-right: 25px;
          &:focus{
            border-color: @dark-blue;
            outline: 0;
            box-shadow: inset 0 1px 1px @dark-blue, 0 1px 1px  @dark-blue;
          }
          &.error{
            border-color : @red-error;
            &:focus{
              border-color: @red-error;
              box-shadow: inset 0 1px 1px @red-error, 0 1px 1px @red-error;
            }
          }
        }
        &:after {
          .icon-font();
          content: @icon-lock;
          position: absolute;
          right: 13px;
          top: 17px;
          transform: translateY(-50%);
          color: @grey-text-light;
          display: block;
          font-size: 1.5em;
        }
      }
      .btn{
        padding: 8px 40px;
      }
      .form-separator{
        border : solid 1px @grey-bg;
        margin-bottom: 18px;
        margin-top: 18px;
      }
      .form-infos{
        margin-top: 7px;
        color : @dark-blue;
        font-style: italic;
        font-size: .85em;
        span{
          margin-right: 10px;
        }
      }
      .error{
        color: @red-error;
        font-size: 13px;
        font-weight: 400;
        margin-bottom: 0;
      }

    }

    #confirm-pane{
      color:@grey-font;
    }

    @media @smup {
      .form-heading {
        h3 {
          font-size: 16px;
        }
      }
    }
    @media @smdwn {
      .form-heading {
        h3 {
          font-size: 14px;
        }
      }
    }


    @media @mdup{
      label.error[generated="true"]{
        position: absolute;
        right: 100%;
        top: 0;
        z-index: 1000;
        display: inline-block;
        background: #b81515;
        color: #FFF;
        padding: 8px;
        width: 175px;
        margin-right: 15px;
        font-weight: 500;
        font-size: 1.2rem;


        &:after {
          left: 100%;
          top: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(184, 21, 21, 0);
          border-left-color: #b81515;
          border-width: 8px;
          margin-top: -8px;
        }
      }
      #demande-error-container{
        position: relative;
      }
    }
    @media @mddwn{
      label.error[generated="true"]{
        display:block;
        text-align: left;
      }
      #demande-error-container{
        margin-bottom:15px;

        .radio-cntnr:nth-child(2){
          margin-bottom:0px;
        }
      }

    }
  }
}