@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,400i,600,700,700i,800');

body {
  font-family: 'Montserrat';
  font-weight: 400;
  color : @dark-blue;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: 'Montserrat';
  line-height: 1.1;
  color: inherit;
}