@icomoon-font-path: "../fonts";

@icon-workman: "\e900";
@icon-pig: "\e901";
@icon-arrow: "\e902";
@icon-check-circle: "\e903";
@icon-arrow-right: "\e904";
@icon-check: "\e905";
@icon-info: "\e906";
@icon-phone: "\e907";
@icon-lock: "\e908";
@icon-menu: "\e909";
@icon-marker: "\e90b";
@icon-marker-outline: "\e90c";
@icon-stars: "\e90d";
@icon-alert: "\e90a";


.icon-font(){
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}