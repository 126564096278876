@import 'env/vars.less';
@import 'env/vars-icofont.less';
@import 'env/icofont.less';
@import 'env/mixins.less';

@import 'parts/0_header.less';
@import 'parts/1_intro.less';
@import 'parts/2_tab-parts.less';
@import 'parts/4_row-4cols.less';
@import 'parts/5_main-content.less';
@import 'parts/6_zdt.less';
@import 'parts/7_partners.less';
@import 'parts/8_banner-1col.less';
@import 'parts/9_pinpoints.less';
@import 'parts/11_testimonials.less';
@import 'parts/11_testimonials.less';
@import 'parts/100_footer.less';

@import 'parts/custom.less';
@import 'parts/components.less';
@import 'parts/form.less';
@import 'parts/font.less';

//BASICS STYLING
html {
  body {
    section{
      padding: 75px 0;
    }
    .titlebar {
      h2 {
        color: @grey-font;
        &:after {
          background: @red;
        }
      }
    }
    .bold {
      font-weight: 600;
    }
    .italic {
      font-style: italic;
    }
    .inline-block {
      display: inline-block;
    }
    .text-white{
      color: @white;
    }
    .red {
      color: @red;
    }
    .underline {
      position: relative;
      &:after {
        content: "";
        border-bottom: 1px solid @red;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        @media @mdup {
          width: 100%;
        }
        @media @mddwn {
          max-width: 230px;
          margin: 0 auto;
        }
      }
    }
    .enlarge-ur-txt{
      font-size: 1.2em;
    }
    .give-me-space{
      margin-bottom: 75px;
    }


    @media @mdup {
      .titlebar h2 {
        margin-bottom: 60px;
        font-size: 30px;
      }
    }
    @media @sm {
      .titlebar h2 {
        margin-bottom: 45px;
        font-size: 26px;
      }
    }
    @media @smdwn {
      .titlebar h2 {
        margin-bottom: 25px;
        font-size: 20px;
      }

      #scrollUp {
        display: none !important;
      }
    }
  }
}