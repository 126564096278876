@import '../env/icofont.less';
@import '../env/vars.less';

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus,
.nav-tabs > li > a:hover{
  color: @dark-blue;
  background-color:transparent;
}
.nav-tabs > li > a{
  background-color:transparent;
  color:#5E5E5E;
}

.tab-pane{
  color:#5E5E5E;
}

#le-groupe .tab-content > .tab-pane p{
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 25px;
  font-weight: 400;
}
html body {
  section {
    .chapo {
      color: @red;
      font-weight: 600;
    }
    .titlebar {
      h2 {
        color: @black;
      }
    }
    //TABS PART
    &#le-groupe {
      @media @smup {
        padding: 50px 0;

        #tabs-holder {
          ul.nav-tabs {
            > li {
              &:first-child {
                border-right: 1px solid #999;
                padding-right: 20px;
              }
              &:last-child {
                padding-left: 20px;
              }
            }
          }
        }
      }
      @media @sm {
        img.img-responsive {
          width: 100%;
          height: auto;
        }
      }
      @media @smdwn {
        padding: 30px 0;
        #tabs-holder {
          ul.nav-tabs {
            text-align: center;
          }
        }
      }
    }
  }
}